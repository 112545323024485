import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12098aea = () => interopDefault(import('../pages/article/index.vue' /* webpackChunkName: "pages/article/index" */))
const _1ed4d6a3 = () => interopDefault(import('../pages/config/index.vue' /* webpackChunkName: "pages/config/index" */))
const _a62b025c = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _98f0c87c = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _5e43730c = () => interopDefault(import('../pages/shipping.vue' /* webpackChunkName: "pages/shipping" */))
const _7c49d252 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _4cca7e95 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _3bd71bd8 = () => interopDefault(import('../pages/template.vue' /* webpackChunkName: "pages/template" */))
const _0bcb2c9a = () => interopDefault(import('../pages/config/delivery.vue' /* webpackChunkName: "pages/config/delivery" */))
const _599a7866 = () => interopDefault(import('../pages/config/guide.vue' /* webpackChunkName: "pages/config/guide" */))
const _7e5491b6 = () => interopDefault(import('../pages/config/history.vue' /* webpackChunkName: "pages/config/history" */))
const _4694ab8c = () => interopDefault(import('../pages/config/mypage.vue' /* webpackChunkName: "pages/config/mypage" */))
const _e814a3d4 = () => interopDefault(import('../pages/config/privacy-policy.vue' /* webpackChunkName: "pages/config/privacy-policy" */))
const _0ec3d736 = () => interopDefault(import('../pages/config/regist-shipping.vue' /* webpackChunkName: "pages/config/regist-shipping" */))
const _10a97574 = () => interopDefault(import('../pages/config/specified-commercial.vue' /* webpackChunkName: "pages/config/specified-commercial" */))
const _d16f318e = () => interopDefault(import('../pages/config/template.vue' /* webpackChunkName: "pages/config/template" */))
const _c5ce825e = () => interopDefault(import('../pages/config/test.vue' /* webpackChunkName: "pages/config/test" */))
const _0155b31c = () => interopDefault(import('../pages/article/_article_id.vue' /* webpackChunkName: "pages/article/_article_id" */))
const _eb4423da = () => interopDefault(import('../pages/complete/_order_id.vue' /* webpackChunkName: "pages/complete/_order_id" */))
const _71a87e64 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/article",
    component: _12098aea,
    name: "article"
  }, {
    path: "/config",
    component: _1ed4d6a3,
    name: "config"
  }, {
    path: "/confirm",
    component: _a62b025c,
    name: "confirm"
  }, {
    path: "/products",
    component: _98f0c87c,
    name: "products"
  }, {
    path: "/shipping",
    component: _5e43730c,
    name: "shipping"
  }, {
    path: "/shop",
    component: _7c49d252,
    name: "shop"
  }, {
    path: "/success",
    component: _4cca7e95,
    name: "success"
  }, {
    path: "/template",
    component: _3bd71bd8,
    name: "template"
  }, {
    path: "/config/delivery",
    component: _0bcb2c9a,
    name: "config-delivery"
  }, {
    path: "/config/guide",
    component: _599a7866,
    name: "config-guide"
  }, {
    path: "/config/history",
    component: _7e5491b6,
    name: "config-history"
  }, {
    path: "/config/mypage",
    component: _4694ab8c,
    name: "config-mypage"
  }, {
    path: "/config/privacy-policy",
    component: _e814a3d4,
    name: "config-privacy-policy"
  }, {
    path: "/config/regist-shipping",
    component: _0ec3d736,
    name: "config-regist-shipping"
  }, {
    path: "/config/specified-commercial",
    component: _10a97574,
    name: "config-specified-commercial"
  }, {
    path: "/config/template",
    component: _d16f318e,
    name: "config-template"
  }, {
    path: "/config/test",
    component: _c5ce825e,
    name: "config-test"
  }, {
    path: "/article/:article_id",
    component: _0155b31c,
    name: "article-article_id"
  }, {
    path: "/complete/:order_id?",
    component: _eb4423da,
    name: "complete-order_id"
  }, {
    path: "/",
    component: _71a87e64,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
